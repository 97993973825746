import React from 'react'

import Layout from '../../components/layout'
import SEO from '../../components/seo'
import TwoColumn from "../../components/two-column"
import CustomerJourney from '../../components/customer-journey'
import Columnar from '../../components/columnar'
import StackedTexts from '../../components/stacked-texts'
import Robots from '../../components/robots'
import PlanAction from '../../components/plan-action'
import FindOutMoreCTA from '../../components/find-out-more-cta'

// import {Helmet} from 'react-helmet'

const bannerContent = {
  title: "Billsby is the perfect subscription billing software for your Internet of Things business.",
  description: [
    {
      text: 'Advanced metered billing support, activate SIM cards with Plan Actions and enable granular upgrades with Feature Tags.'
    }
  ],
  withPromotion: false,
  textColor: "#252422",
  backgroundColor: "#F3F5FB",
  backgroundImage: require('../../images/solution-internetofthings@2x.png'),
  image: 'solution-internetofthings.png',
  imageName: 'pro-development image',
  backgroundPosition: 'right',
  hasBg: false,
  healthcareAndEducation: true,
  iot: true,
  buttons: [
    {
      isExternal: true,
      url: 'https://app.billsby.com/registration',
      buttonColor: 'orange',
      buttonText: 'Sign up today'
    },
    {
      scheduleButton: true,
      buttonColor: 'black',
    }
  ],
  isBanner: true,
}

const billsbySetup = {
  title: "Get setup quickly and easily and speed up your time to market.",
  list: [
    {
      title: 'No or minimal development required',
      description: <>We've invested millions of dollars and tens of thousands of development hours into building a flexible billing platform so that you and your team don't have to. Our system covers hundreds of billing scenarios, and lets you get setup in as little as a few hours and starting to charge customers.</>,
    },
    {
      title: 'Don\'t worry about changes to rules and regulations',
      description: <>The payments industry is constantly changing rules and regulations, which can really slow down your SaaS business. With Billsby, as rules change we'll ensure your billing solution is automatically updated and adapted to suit them. So you don't need to watch your back.</>,
    },
  ],
  image: 'check-clock.png',
  imageName: 'billsby-payment'
}

const realtimeChanges = {
  title: "Make changes in real time with complete commercial flexibility.",
  list: [
    {
      title: 'Create unlimited products, plans and cycles',
      description: <>You can create new products, plans and cycles whenever you want with Billsby - and they're all fully addressable through the API. Because of our unique approach to product, plan and cycle creation, and Feature Tags for access control, adding new plans doesn't require development intervention.</>,
    },
    {
      title: 'Switch payment gateways at any time ',
      description: <>If there's a contract change, or you're required to do regular RFPs for payment gateways, you don't want to be stuck with not being able to setup a new integration. You can switch customers immediately to any supported gateway with Billsby.</>,
    },
  ],
  image: 'city-lights.png',
  imageName: 'billsby-realtime',
  align: 'right'
}

const manageDunning = {
  title: "Understand why your customers leave and manage dunning.",
  list: [
    {
      title: 'Prevent and reduce churn',
      description: <>Customers leave, but our retention tools can help minimise the impact of churn on your business and explain why they leave when they choose to do so. This helps you to grow your business more quickly, and make changes to your product to stop inhibiting your growth.</>,
    },
    {
      title: 'Dunning is done for you',
      description: <>When payments decline, it's important to reach out to customers and get them back on track. Our advanced dunning tools make this process easy, and ensure that as much revenue is captured for your business as possible. All without writing a single line of code.</>,
    },
  ],
  image: 'smart-watch.png',
  imageName: 'billsby-manage-dunning',
}

const columnarContent = [
  {
    title: "Reduce your PCI-DSS compliance burdens and headaches.",
    list: [
      {
        title: '',
        description: <>Billsby is PCI-DSS compliant and whether you use our JavaScript checkout or our card tokenizer, you'll never store payment card numbers or details anywhere on your server or infrastructure, or have to transfer them to us. This significantly reduces your compliance challenges.</>,
      },
    ]
  },
  {
    title: "Spend less time integrating systems and services.",
    list: [
      {
        title: '',
        description: <>You'll never have to worry about payment gateway integrations again - and your business will even have the flexibility to switch gateways at any time with no impact on you or your development cycle - it only takes a click to immediately start billing through an alternate provider.</>,
      },
    ]
  }
]

const robotContent = {
  bgColor: 'blue',
  title: 'Our top five features for Internet of Things companies',
  titleColor: 'white',
  block: [
    {
      img: require('../../images/button-addons.svg'),
      altText: 'add-ons-and-allowances',
      text: 'ADD-ONS AND ALLOWANCES',
      url: '/product/creating/addons-allowances',
    },
    {
      img: require('../../images/button-retention.svg'),
      altText: 'dunning-n-retention',
      text: 'DUNNING AND RETENTION',
      url: '/product/subscribers/dunning-retention',
    },
    {
      img: require('../../images/billsby-account-mgmt.svg'),
      altText: 'ACCOUNT MANAGEMENT',
      text: 'ACCOUNT MANAGEMENT',
      url: '/product/subscribers/account-management',
    },
    {
      img: require('../../images/button-salestax.svg'),
      altText: 'SALES TAX',
      text: 'SALES TAX',
      url: '/product/subscribers/sales-tax-and-compliance',
    },
    {
      img: require('../../images/button-invoice-emails.svg'),
      altText: 'INVOICE AND EMAILS',
      text: 'INVOICE AND EMAILS',
      url: '/product/subscribers/invoices-creditnotes-emails',
    }
  ]
}

const planActionContent = {
  title: "Power your subscriptions with Zapier.",
  description: "Once customers sign-up and as their subscription changes, use Zapier to complete follow up tasks across all of the other tools and services you use to run your business.",
  image: require('../../images/plan-action.svg'),
  imageName: 'plan action image',
  svg: true,
  cards: [
    {
      image: 'plan-action-1.png',
      text: 'Add the new customer to your CRM system',
    },
    {
      image: 'plan-action-2.png',
      text: 'Subscribe them to the correct mailing list',
    },
    {
      image: 'plan-action-3.png',
      text: 'Post out their welcome pack',
    }
  ],
  url: '/product/integrations/zapier',
  buttonColor: 'blue',
  buttonText: 'Learn about Zapier',
  comingSoonText:''
}

const behindPaywalls = {
  image: require('../../images/support-talk.svg'),
  imageName: 'billsby-payment',
  svg: true,
  stackedTexts : [
    {
      title: "We're here to help you get started, get setup and see quick ROI.",
      list: [
        {
          title: '',
          description: <>Our account management team can help you to get setup with Billsby, answer your implementation questions and point you in the right direction to get started. If you're doing development in house, our developer community, email support, chat support and phone call-backs are included with every plan. </>,
        },
        {
          title: '',
          description: <>If you need development assistance, we can help with Pro Development Services too - our all-inclusive end-to-end developed for you solution that can integrate Billsby into your existing business and processes at a surprisingly affordable price. Let us know how we can help you to succeed.</>
        }
      ],
      buttons: [
        {
          scheduleButton: true,
          btnColor: 'blue',
          classConfig: 'tight-padding'
        },
        {
          btnColor: 'blue',
          btnText: 'Pro Developement Services',
          urlType: 'internal',
          url: '/pro/development',
          classConfig: 'tight-padding'
        }
      ]
    }
  ]
}

const findOutMoreCTAContent = {
  title: "Explore away, there's no credit card required",
  description: "We know that sometimes the best way to evaluate a solution is to try it for yourself, so we've made sure that signing up doesn't require a credit card, and our onboarding team are standing by to help with any questions.",
  url: "/pro/development",
  backgroundColor: '#F3F5FB',
  buttons : [
    {
      isExternal: true,
      url: 'https://app.billsby.com/registration',
      buttonText: "Sign up today",
      buttonColor: "orange"
    },
    {
      scheduleButton: true,
      buttonColor: "black"
    }
  ]
}

const IoT = () => {
  return (
    <Layout className="solutions iot">
      <SEO 
        title="Internet of Things subscription billing | Billsby" 
        description="A perfect subscription billing software for Internet of Things businesses, providing detailed billing reports, insights, and api management services | Billsby" 
        url="https://www.billsby.com/solutions/internet-of-things"
      />

      <TwoColumn content={bannerContent} />
      <CustomerJourney content={billsbySetup} />
      <CustomerJourney content={realtimeChanges} />
      <CustomerJourney content={manageDunning} />
      <Robots content={robotContent} />
      <Columnar content={columnarContent} />
      <PlanAction content={planActionContent} />
      <StackedTexts content={behindPaywalls} />
      {/* <FindOutMoreCTA content={findOutMoreCTAContent} /> */}
    </Layout>
  )
}

export default IoT